import React from "react"
import * as faqStyles from "./faq.module.scss"

const Faq = () => {
  return (
    <section className={faqStyles.wrapper} id="faq">
      <h2 className={faqStyles.header}>Commonly Asked Questions</h2>
      <ul className={faqStyles.questionsWrapper} role="group">
        <li className={faqStyles.singleQuestion} aria-label="FAQ">
          <h3 className={faqStyles.question}>Do I Need an Appointment?</h3>
          <p className={faqStyles.answer}>
            Modern Eye is currently open by appointment only. If you arrive
            without an appointment, we will try to accomodate you if we have the
            space. Bear in mind, however, that those who have scheduled an
            appointment in advance will be given priority. An appointment is
            ALWAYS required for eye examinations.
          </p>
        </li>
        <li className={faqStyles.singleQuestion} aria-label="FAQ">
          <h3 className={faqStyles.question}>Do You Take Insurance?</h3>
          <p className={faqStyles.answer}>
            Most major medical insurance is accepted if you have a medical
            complaint. Separate vision plans cover or partially cover eye exams
            and often eyeglasses and/or contact lenses. Currently, Modern Eye is
            a provider for the following vision plans: Vision Service Plan (VSP)
            and Vision Benefits of America (VBA).
          </p>
        </li>
        <li className={faqStyles.singleQuestion} aria-label="faq">
          <h3 className={faqStyles.question}>Do I Need to Wear A Mask?</h3>
          <p className={faqStyles.answer}>
            In an effort to keep everyone safe during the Covid-19 Pandemic, we
            are requiring masks or facial coverings for entry (we can provide
            one if needed!). We have implemented protocols and practices to
            allow for safe and effective frame shopping.
          </p>
        </li>
      </ul>
    </section>
  )
}

export default Faq
