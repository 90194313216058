import React from "react"
import ContactForm from "../components/contact/contactForm"
import ContactTop from "../components/contact/contactTop"
import Emails from "../components/contact/emails"
import Faq from "../components/contact/faq"
import * as contactStyles from "./contact.module.scss"
import Seo from "../components/seo"

const Contact = () => {
  return (
    <div className={contactStyles.wrapper}>
      <Seo
        title="Contact Us"
        description="Fill out the form below to send us a message. Be sure to read our commonly asked questions to see if your question has already been answered."
      />
      <ContactTop />
      <ContactForm />
      <Emails />
      <Faq />
    </div>
  )
}

export default Contact
