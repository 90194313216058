import React, { useState, useRef, useEffect } from "react"
import * as contactFormStyles from "./contactForm.module.scss"
import scrollTo from "gatsby-plugin-smoothscroll"

const ContactForm = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  })

  const [isSubmitted, setIsSubmitted] = useState(false)
  const thankYouRef = useRef(null)

  const resetForm = () => {
    setFormState({
      name: "",
      email: "",
      message: "",
      phone: "",
    })
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => {
        setIsSubmitted(true)
      })
      .catch(error => alert(error))
    resetForm()
    e.preventDefault()
  }

  useEffect(() => {
    if (isSubmitted) {
      thankYouRef.current.focus()
    }
  }, [isSubmitted])

  return (
    <section>
      <div className={contactFormStyles.formContainer}>
        {isSubmitted ? (
          <p
            className={contactFormStyles.thankYou}
            tabIndex={0}
            ref={thankYouRef}
          >
            Thanks for your message. Our staff will be in touch soon!
          </p>
        ) : (
          <>
            <p className={contactFormStyles.faqNotice}>
              Fill out the form below to send us a message. Be sure to read our{" "}
              <span
                className={contactFormStyles.faqLink}
                onClick={() => scrollTo("#faq")}
                onKeyDown={() => scrollTo("#faq")}
                role="button"
                tabIndex={0}
                aria-label="Jump to FAQ"
              >
                commonly asked questions
              </span>{" "}
              below to see if your question has already been answered. To make
              an appointment please{" "}
              <span
                className={contactFormStyles.faqLink}
                onClick={() => scrollTo("#footer")}
                onKeyDown={() => scrollTo("#footer")}
                role="button"
                tabIndex={0}
                aria-label="Jump to footer"
              >
                give us a call
              </span>
              .
            </p>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact"></input>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name*"
                onChange={handleChange}
                value={formState.name}
                required
              />
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email*"
                onChange={handleChange}
                value={formState.email}
                required
              />
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Your Phone Number"
                onChange={handleChange}
                value={formState.phone}
              />
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                onChange={handleChange}
                value={formState.message}
                placeholder="Type a Message*"
                style={{ height: `200px` }}
                required
              ></textarea>
              <button
                type="submit"
                value="Submit"
                style={{ marginTop: `3rem` }}
              >
                Submit
              </button>
            </form>
          </>
        )}
      </div>
    </section>
  )
}

export default ContactForm
