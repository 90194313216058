// extracted by mini-css-extract-plugin
export var bil = "emails-module--bil--Ksdar";
export var brooke = "emails-module--brooke--rMDgk";
export var docmach = "emails-module--docmach--+c+53";
export var drchris = "emails-module--drchris--rrbn0";
export var drteich = "emails-module--drteich--90QaL";
export var emailLink = "emails-module--emailLink--3yVG6";
export var emailsHeaders = "emails-module--emailsHeaders--tVcDC";
export var emailsWrapper = "emails-module--emailsWrapper--BsR23";
export var kate = "emails-module--kate--XSO4X";
export var section = "emails-module--section--At2HB";
export var staffNames = "emails-module--staffNames--TOhIF";
export var tiffanypao = "emails-module--tiffanypao--V-uPB";
export var will = "emails-module--will--zN+Vo";