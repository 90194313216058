import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as contactTopStyles from "./contactTop.module.scss"

const ContactTop = () => {
  return (
    <div className={contactTopStyles.wrapper}>
      <div className={contactTopStyles.redLine}></div>
      <div>
        <StaticImage
          src="../../images/contact/contactDrapery3.png"
          placeholder="tracedSVG"
          alt="Illustration of an ear, an eye, and a megaphone under drapery. Hidden behind these elements is a faint page of a phone book."
        />
        <h1 className={contactTopStyles.contactHeader}>CONTACT US</h1>
      </div>
    </div>
  )
}

export default ContactTop
