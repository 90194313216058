import React from "react"
import * as emailsStyles from "./emails.module.scss"

const Emails = () => {
  return (
    <section>
      <div className={emailsStyles.emailsWrapper}>
        <div className={emailsStyles.section}>
          <h2 className={emailsStyles.emailsHeaders}>Doctors</h2>
          <a
            className={emailsStyles.emailLink}
            href="mailto:drchris@modern-eye.com"
            title="Email Dr. Chris"
          >
            <div className={emailsStyles.staffNames}>
              Christopher Anastasiou, OD(Owner)
            </div>
            <address className={emailsStyles.drchris} aria-hidden="true">
              @
            </address>
          </a>
          <a
            className={emailsStyles.emailLink}
            href="mailto:docmach@modern-eye.com"
            title="Email Dr. Machamer"
          >
            <div className={emailsStyles.staffNames}>Bryan Machamer, OD</div>
            <address className={emailsStyles.docmach} aria-hidden="true">
              @
            </address>
          </a>
          <a
            className={emailsStyles.emailLink}
            href="mailto:drteich@modern-eye.com"
            title="Email Dr. Teich"
          >
            <div className={emailsStyles.staffNames}>Justin Teich, OD</div>
            <address className={emailsStyles.drteich} aria-hidden="true">
              @
            </address>
          </a>
          <a
            className={emailsStyles.emailLink}
            href="mailto:tiffanypao@modern-eye.com"
            title="Email Dr. Pao"
          >
            <div className={emailsStyles.staffNames}>Tiffany Pao, OD</div>
            <address className={emailsStyles.tiffanypao} aria-hidden="true">
              @
            </address>
          </a>
        </div>

        <div className={emailsStyles.section}>
          <h2 className={emailsStyles.emailsHeaders}>Store Managers</h2>
          <a
            className={emailsStyles.emailLink}
            href="mailto:kate@modern-eye.com"
            title="Email Kate Alexander"
          >
            <div className={emailsStyles.staffNames}>
              Kate Alexander (University City)
            </div>
            <address className={emailsStyles.kate} aria-hidden="true">
              @
            </address>
          </a>
          <a
            className={emailsStyles.emailLink}
            href="mailto:will@modern-eye.com"
            title="Email Will Welsh"
          >
            <div className={emailsStyles.staffNames}>
              Will Welsh (Center City)
            </div>
            <address className={emailsStyles.will} aria-hidden="true">
              @
            </address>
          </a>
        </div>

        <div className={emailsStyles.section}>
          <h2 className={emailsStyles.emailsHeaders}>Lab Managers</h2>
          <a
            className={emailsStyles.emailLink}
            href="mailto:brooke@modern-eye.com"
            title="Email Brooke Morris, ABOC"
          >
            <div className={emailsStyles.staffNames}>
              Brooke Morris, ABOC (University City)
            </div>
            <address className={emailsStyles.brooke} aria-hidden="true">
              @
            </address>
          </a>
          <a
            className={emailsStyles.emailLink}
            href="mailto:bil@modern-eye.com"
            title="Email Bil Hall, ABOC"
          >
            <div className={emailsStyles.staffNames}>
              Bil Hall, ABOC (Center City)
            </div>
            <address className={emailsStyles.bil} aria-hidden="true">
              @
            </address>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Emails
